html, body, #root {
    height: 100%;
}

:root {
    --main-color: #ffff;
    --nav-color: #e6e6e6e5;
    --jet: #343434;
    --cyan: #3CB3E4;
    --black: #001111;
    --grey: #919191;
}

body {
    font-family: 'inter-regular', sans-serif;
    scroll-behavior: smooth;
    background-color: var(--main-color);
    color: var(--black);
}

.pac-container {
    z-index: 10000 !important;
}

.mapZipSearchDiv {
    display: grid;
    place-items: center;
}

.mapZipSearch {
    z-index: 1000;
    position: absolute;
    margin-top: 75px;
    border-style: solid;
    border-width: 10px;
    border-color: lightblue;
    opacity: .7;
    background: whitesmoke;
    font-weight: 900;
}

.noButton {
    outline: none;
    border: none;
    background: none;
    padding: 0px;
}

.button {
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid var(--jet);
    outline: none;
    background-color: var(--main-color);
}

.button:hover {
    box-shadow: 1px 1px 5px var(--jet);
}

.button:active {
    outline: none;
    box-shadow: none;
}

input, textarea, select {
    outline: none;
    border-radius: 5px;
    background-color: var(--main-color);
}


input:invalid:focus, textarea:invalid:focus, select:invalid:focus {
    border: 2px solid red;
}


.text-link {
    color: var(--grey);
}

.text-link:hover {
    text-decoration: none;
    color: var(--cyan);
}

/* Navbar Styles */

.navMain {
    max-height: 200px;
    border-bottom: 3px solid var(--cyan);
}

.navList {
    list-style: none;
}

.navListItems {
    margin-right: 7px;
    margin-left: 7px;
    font-size: 17px;
}

.navListItems a {
    text-decoration: none;
    color: var(--black);
    /* text-shadow: 0px 0px 2px var(--black); */
}

.navbarLogo {
    content: url('./assets/images/NavbarLogo.png');
    height: 100%;
    width: 250px;
}

.unreadNotifications {
    border-radius: 50%;
    background-color: var(--cyan);
    border: 2px solid black;
}

.accountOffCanvas {
    background-color: var(--main-color);
    border-left: 5px solid var(--cyan);
    padding-left: 0;
}

.accountSelection {
    border-bottom: 2px solid var(--cyan);
    cursor: pointer;
}

.accountSelection:hover {
    background-color: var(--cyan);
}

.accountSelection:active {
    background-color: var(--grey);
}

.accountSelection a {
    text-decoration: none;
    color: var(--black);
}

.signOutDiv {
    background-color: var(--main-color);
    border: 4px solid var(--cyan);
    border-radius: 10px;
    min-height: 15vh;
}

.modalForm {
    background-color: var(--main-color);
}

.modalFormInput {
    width: 50%;
    margin-left: auto;
}

/* Main Styles */

.searchCriteriaDiv {
    border-right: 2px solid var(--cyan);
}

.sortRow {
    border-bottom: 2px solid var(--cyan);
}

.mainMap {
    height: 100%;
    width: 90%;
}

.selectorMap {
    height: 300px;
    width: 90%;
}

.postCards {
    box-shadow: 1px 1px 8px black;
    height: 490px;
    position: relative;
}

.postCards:hover {
    box-shadow: 1px 1px 20px var(--cyan);
    cursor: pointer;
}

.postCardsMarker {
    box-shadow: 1px 1px 20px var(--cyan) !important;
    cursor: pointer !important;
}

.postCardsImage {
    float: left;
    width: 100%;
    height: 250px;
    object-fit: cover;
    padding: 0;
}

.postCardsImageDiv {
    max-height: 200px;
    background: radial-gradient(var(--main-color), gray);
    margin: 0;
}

.tipImage {
    max-height: 250px;
    max-width: 100%;
    box-shadow: 1px 1px 5px black;
    object-fit: contain;
}

.loginDiv {
    box-shadow: 1px 0px 10px var(--cyan);
    border: 1px solid var(--cyan);
    border-radius: 10px;
}

.loginDiv input {
    width: 100%;
}

.loginDiv .show_password {
    position: absolute;
    right: 10px;
    top: 2px;
    text-decoration: none;
}

.imageCarousel {
    border-bottom: 5px solid var(--cyan);
    padding: none;
}

.imageCarousel li {
    background-color: var(--jet);
}

.axis-vertical, .thumbs-wrapper {
    margin: 0px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    overflow-x: auto !important;
}

.thumb {
    border: none !important;
    box-shadow: 1px 1px 7px black;
    max-height: 10vh;
    min-width: 15vw !important;
    max-width: 20vw !important;
    object-fit: contain;
    margin-right: 20px !important;
    padding: 0 !important;
    background-color: var(--main-color) !important;
}

.thumb.selected {
    border: 4px solid var(--cyan) !important;
    box-shadow: none;
}

.carouselImages {
    float: left;
    width: 100%;
    height: 40vh;
    object-fit: contain;
    padding: 0;
}

.carouselVideoDiv {
    position: relative;
    width: 100%;
    max-height: 40vh;
    padding-bottom: 40vh;
}

.carouselVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 40vh;
}

/* Individual post styles */

.borderB_grey {
    border-bottom: 2px solid var(--grey);
}

.borderB_grey_sm {
    border-bottom: 1px solid var(--grey);
}

.borderB_cyan {
    border-bottom: 2px solid var(--cyan);
}

.individualInfoDivs {
    width: 25vw;
}

.showTips {
    box-shadow: 0px 0px 3px var(--cyan);
    border-radius: 6px;
}

.tipUsername {
    border-top: 2px solid var(--jet);
    border-left: 2px solid var(--jet);
    border-top-left-radius: 5px;
}

/* --------------------------------------- */
/* ----- Media Queries ----- */
/* --------------------------------------- */


@media screen and (max-width: 1200px) {
    /* xl */

}

@media screen and (max-width: 992px) {
    /* lg */
    .postCardsImage {
        height: 350px;
    }

    .postCardsImageDiv {
        max-height: 350px;
    }

    .postCards {
        height: 590px;
    }

    .thumb {
        max-height: 15vh;
        min-width: 25vw !important;
        max-width: 30vw !important;
    }
}

@media screen and (max-width: 768px) {
    /* md */
    .navbarLogo {
        content: url('./assets/images/NavbarLogo-Mobile.png');
    }

    .mainMap {
        width: 100%;
    }

    .searchCriteriaDiv {
        border-bottom: 5px solid var(--cyan);
        border-right: none;
    }

    .carouselImages {
        height: 50vh;
    }

    .individualInfoDivs {
        width: 35vw;
    }

    .carouselImages {
        height: 30vh;
    }

    .carouselVideoDiv {
        padding-bottom: 30vh;
    }

    .carouselVideo {
        max-height: 40vh;
    }
}

@media screen and (max-width: 576px) {
    /* sm */
    .postCardsImage {
        max-height: 250px;
    }

    .postCardsImageDiv {
        max-height: 250px;
    }

    .thumb {
        max-height: 15vh;
        min-width: 35vw !important;
        max-width: 45vw !important;
    }
}

@media screen and (min-width: 1400px) {
    /* xxl */
    .postCardsImage {
        height: 300px;
    }

    .postCardsImageDiv {
        max-height: 300px;
    }

    .postCards {
        height: 550px;
    }

    .individualContainer {
        border-left: 5px solid var(--cyan);
        border-right: 5px solid var(--cyan);
    }

    .thumb {
        max-height: 15vh;
        min-width: 10vw !important;
        max-width: 15vw !important;
    }

    .individualInfoDivs {
        width: auto;
    }
}

.modal-content {
    max-width: 600px !important;
    margin-left: 7rem;
}

.alertify-notifier .ajs-message.ajs-visible {
    margin-top: 10px !important;
    border-radius: 10px !important;
    box-shadow: 1px 0px 10px var(--cyan) !important;
    font-size: 17px !important;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 375px !important;
    top: 70px !important;
}

.alertify-notifier .ajs-message {
    width: 350px !important;
}

.alertify-notifier .ajs-message.ajs-error {
    color: black !important;
    background-color: #ff0000b5 !important;
}

.alertify-notifier .ajs-message.ajs-success {
    color: black !important;
    background-color: #00ff008a !important;
}

.no-after button:after{
    content:none !important;
}

.carousel ul.slider li.selected {
    cursor: zoom-in !important;
}

/* ----- End Media Queries ----- */
