.post-file-container {
    position: relative;
    border-bottom: 0 !important;
    margin: 16px;
}

.hover--on .delete-button {
    display: block;
}

.hover--off .delete-button {
    display: none;
}
